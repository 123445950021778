<template>
  <div class="home">
    <div><titleTab></titleTab></div>
    <router-view/>
    

  </div>
</template>

<script>
import titleTab from "../components/navigation.vue"

export default {
  name: 'Home',
 data(){
  return{}
 },
 components:{
  titleTab
 },
}
</script>
<style scoped lang='less'> 
.home{
  width: 100%;
  // min-width: 1000px;
  overflow: hidden;
}

</style>
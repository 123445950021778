<template>
  <div class="home">
    <div class="home_title">
      <img  src="../assets/img/XIWEN.png" alt="">
      <div class="title"> 
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1" @click="getjump('/','1')">首页</el-menu-item>
          <el-menu-item index="2" @click="getjump('/productCenter','2')">产品中心</el-menu-item>
          <el-menu-item index="3" @click="getjump('/solution','3')">解决方案</el-menu-item>
          <el-menu-item index="4" @click="getjump('about','4')">关于禧闻</el-menu-item>
          <el-menu-item index="5" @click="getjump('contactUs','5')">联系我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
 data(){
  return{
    activeIndex: '1',
  }
 },

 mounted(){
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      console.log('--->123123')
      this.activeIndex = sessionStorage.getItem("activeIndex");
    });

  this.getActiveIndex();
 },
 beforeDestroy() {
    // 移除对 sessionStorage 的监听
    window.removeEventListener("setItem", () => {});
  },

 methods: {
   getActiveIndex(){
    var activeIndex = sessionStorage.getItem('activeIndex');
    console.log(activeIndex);
    this.activeIndex = activeIndex;
   },
   reset(){

   },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    getjump(val,key){

      this.$router.push({
        path:val
      })
      // this.activeIndex = key;
      this.setSessionItem('activeIndex',key);
    }
  }
}
</script>
<style scoped lang='less'> 
ul , ul li{
  list-style: none;
  margin: 0;padding: 0;
}
.home{
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.home_title{
  display: flex;
  width: 1000px;
  height: 80px;
  line-height: 80px;
  margin: 0 auto;
  justify-content: space-between;
  // 
  img{
    width: 126px;
    height: 34px;
    margin-top: 23px;
  }
  .title ul{
    font-size: 18px;
    li{
      width: 130px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }
    li:hover{
      color: #0b5394;
      font-weight: 700;
    }
  }
}
.el-menu.el-menu--horizontal{
  border-bottom: none;
}
.el-menu-item{
  font-size: 18px;
}
.el-menu--horizontal>.el-menu-item{
  color: #4a4a4a;
}
.el-menu--horizontal>.el-menu-item.is-active{
  color: #0b5394 !important;
  font-weight: 700;
  border-bottom: 3px solid #073763;
}
.el-menu--horizontal>.el-menu-item.is-active:hover{
  color: #0b5394;
  border-bottom: 3px solid #073763;
}

@media (max-width: 750px) {
  .home_title{
    width:100%;
    display: block;
    height: 20%;
    img{
      display: block;
      margin-top: 10px;
    }
    .title ul{
      margin-top: 10px;
    li{
      width: 20%;
      height: 50px;
      line-height: 50px;
    }
  }
  .el-menu-item{
      font-size: 14px;
    }
  }
}
</style>
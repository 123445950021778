import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/leader",
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      {
        path: "leader",
        component: () => import("../views/leader.vue"),
      },
      {
        path: "about",
        component: () => import("../views/about.vue"),
      },
      {
        path: "productCenter",
        component: () => import("../views/productCenter.vue"),
      },
      {
        path: "productDetail",
        component: () => import("../views/productDetail.vue"),
      },
      {
        path: "solution",
        component: () => import("../views/solution.vue"),
      },
      {
        path: "solutionDetail",
        component: () => import("../views/solutionDetail.vue"),
      },
      {
        path: "contactUs",
        component: () => import("../views/contactUs.vue"),
      },
      
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
